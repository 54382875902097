<template>
    <VideoView class='newvid' video-id="020a34e551904512878340d5cb31f0a3"/>
</template>

<script>
    import VideoView from "../Widgets/VideoView";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView
        }
    }
</script>

<style>
    .newvid {
        max-width: 950px;
        margin: 0 auto;
    }
</style>